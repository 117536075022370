<template>
  <div >
    <el-container style="height: 100vh;overflow: auto;" class="homemain">
      <!--Backtop回到顶部，target对应div的class，必须设置height，overflow属性-->
      <el-backtop target=".homemain"></el-backtop>
      <el-header>
        <el-row>
          <el-col :span="1" style="height: 60px"><a href="blogmain"><img src="../../../assets/image/z.png" alt=""></a></el-col>
          <el-col :span="8"><a href="blogmain"><div class="home-main">张中正个人博客|技术博客</div></a></el-col>
          <el-col :span="8">
            <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect"
                     background-color="#fff"
                     text-color="#222222"
                     active-text-color="#4a90e2">
              <el-menu-item index="1" class="fa fa-home"  style="letter-spacing: 2px">首页</el-menu-item>
              <el-menu-item index="2" class="fa fa-briefcase" style="letter-spacing: 2px">工具</el-menu-item>
              <el-menu-item index="3" class="fa fa-info-circle" style="letter-spacing: 2px">关于</el-menu-item>
              <el-menu-item index="4" class="fa fa-commenting" style="letter-spacing: 2px">留言</el-menu-item>
            </el-menu>
          </el-col>
          <el-col :span="5">
            <div style="text-align: left">
              <el-input placeholder="请输入内容" v-model="searchcontent" clearable @change="onSearch">
                <template slot="append">站内搜索</template>
              </el-input>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside style="padding-top: 20px;padding-bottom: 20px;width: 350px;">
          <!--热门文章-->
          <el-card class="box-card">
            <el-row class="elrow">
              <el-col :span="12" style="text-align: left;margin-left: 20px;"><div>热门文章</div></el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row class="elrow">
              <el-col :span="24" style="text-align: left;margin-left: 20px; margin-top:5px;margin-bottom: 5px; color: #222222" v-for="item in hotlist">
                <div><el-link :href="'/article?val='+item.Id" >{{item.Tittle}}</el-link></div>
              </el-col>
            </el-row>
          </el-card>

          <!--最近评论-->
            <el-card class="box-card">
              <el-row class="elrow">
                <el-col :span="12" style="text-align: left;margin-left: 20px;"><div>最近评论</div></el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row class="elrow">
                <el-col :span="24" style="text-align: left;margin-left: 20px; margin-top:5px;margin-bottom: 5px; color: #222222" v-for="item in replylist">
                  <div><el-link :href="'/article?val='+item.Blogid" >{{item.Tittle}}</el-link></div>
                  <div style="color: #ee0a24;">{{item.Nickname}}：{{item.Content}}</div>
                </el-col>
              </el-row>
            </el-card>

        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
        <el-aside style="padding-top: 20px;padding-bottom: 20px;width: 350px;">

          <!--文章标签-->
          <el-card class="box-card-tag">
            <el-row class="elrow">
              <el-col :span="12" style="text-align: left;margin-left: 20px;"><div>随机标签</div></el-col>
            </el-row>
            <el-divider></el-divider>
            <div>
              <el-link :href="'/tag?val='+item.Id" v-for="item in taglist" :underline="false">
                <el-tag type="success" style="cursor: pointer">{{item.Tagname}}</el-tag>
              </el-link>
            </div>
          </el-card>

          <!--文章分类-->
          <el-card class="box-card-tag">
            <el-row class="elrow">
              <el-col :span="12" style="text-align: left;margin-left: 20px;"><div>文章分类</div></el-col>
            </el-row>
            <el-divider></el-divider>
            <div>
              <el-row class="elrow">
                <el-col :span="10" style="margin: 10px" v-for="item in classifylist">
                  <el-link :href="'/classify?val='+item.Id" :underline="false">
                    <el-button size="small">{{item.Classifyname}}</el-button>
                  </el-link>
                </el-col>
              </el-row>
            </div>
          </el-card>

          <!--快捷链接-->
          <el-card class="box-card-tag">
            <el-row class="elrow">
              <el-col :span="12" style="text-align: left;margin-left: 20px;"><div>快捷链接</div></el-col>
            </el-row>
            <el-divider></el-divider>
            <div>
              <el-row class="elrow">
                <el-col :span="6" style="margin: 10px" v-for="item in bloglinklist">
                  <el-link type="warning" :href="item.Link" target="_blank">{{item.Linkname}}</el-link>
                </el-col>
              </el-row>
            </div>
          </el-card>

        </el-aside>
      </el-container>
      <el-footer>
        <div>Copyright © 2021-2021 <el-link href="/" target="_self" :underline="false">张中正个人博客</el-link> All rights reserved. <el-link href="http://beian.miit.gov.cn" target="_blank" :underline="false">豫ICP备2021009059号</el-link>
          <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=757793207&site=qq&menu=yes">
            联系方式
          </a>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import {GetBlogClassifyBaseRandomList, GetBlogReply, GetBlogTagsBaseRandomList, GetHotBlogList} from "@/network/blog";
import {GetBlogLinkList} from "@/network/tags";

export default {
  name: "Index",
  data(){
    return{
      activeIndex: '1',
      searchcontent:'',
      hotlist:[],
      replylist:[],
      taglist:[],
      classifylist:[],
      bloglinklist:[],
    }
  },
  methods:{
    handleSelect(key, keyPath){
      this.activeIndex =key
      if (key==='1'){
        this.$router.push({path: '/blogmain'})
      }else if(key==='2'){
        this.$router.push({path: '/tool'})
      }else if(key==='3'){
        this.$router.push({path: '/about'})
      }else if(key==='4'){
        this.$router.push({path: '/lmessage'})
      }
    },
    onSearch(){
      if (this.searchcontent==='')
        return
      this.$router.push({path: '/search',query:{val:this.searchcontent}})
    },
    onlink(value){
      console.log('文章被点击！')
    },
    //初始化热门文章
    inithot(){
      GetHotBlogList().then(res => {
        this.hotlist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    //初始化最新评论
    initreply(){
      GetBlogReply().then(res => {
        this.replylist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    //随机tag
    inittag(){
      GetBlogTagsBaseRandomList().then(res => {
        this.taglist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    //随机分类
    initclassfity(){
      GetBlogClassifyBaseRandomList().then(res => {
        this.classifylist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    ontittle(id){
      this.$router.push({path: '/article', query: {val: id}})
    },
    bloglink(){
      GetBlogLinkList().then(res => {
        this.bloglinklist =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    initindex(){

      let path = this.$route.fullPath.replace('/','')
      console.log(path)
      if (path==='tool'){
        this.activeIndex='2'
      }else if(path==='blogmain'){
        this.activeIndex='1'
      }
      else if(path==='about'){
        this.activeIndex='3'
      }
      else if(path==='lmessage'){
        this.activeIndex='4'
      }
    }
  },
  created() {
    //热门文章
    this.inithot()
    //最新评论
    this.initreply()
    //随机tag
    this.inittag()
    //随机分类
    this.initclassfity()
    //博客链接
    this.bloglink()
    //刷新页面加载index
    this.initindex()
  }
}
</script>

<style scoped>

.el-header{
  background-color: #fff;
  line-height: 60px;
}

.el-row{
  height: 60px;
  line-height: 60px;
}

.el-col img{
  height: 60px;
}

.home-main{
  font-size: 30px;
  font-family: 楷体;
  color: #222222;
  padding-left: 0px;
}

.el-footer {
  background-color: #B3C0D1;
  text-align: center;
  line-height: 40px;
  height: 40px;
  font-size: 18px;
  color: #333333;
}
.el-footer .el-link{
  font-size: 18px;
  color: #333333;
}
.el-footer a{
  font-size: 18px;
  color: #333333;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

.el-aside {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}
.el-dropdown-link {
float: right;
}

.box-card {
  margin: 0 10px;
  padding-bottom:15px;
  margin-bottom: 20px;
}

.box-card-tag {
  margin: 0 10px;
  margin-bottom: 20px;
}

.elrow{
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  font-family: 微软雅黑;
  color: #1989fa;
}
.el-divider{
  margin: 0 0;
}

.el-tag{
  margin: 5px;
}
</style>